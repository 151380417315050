<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import NewsItem from "@/components/news-item/news-item"
import Sort from "@/components/sort"
import FastImport from "@/components/FastImport"

import {
  formatRegionNewsList,
  formatImportNews,
  RegionSettingSchema
} from '@/views/pages/marketing-slot/home-index/common.js'

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "分頁新聞版位設定",
      items: [
        {
          text: "分頁新聞版位",
          href: "/announcement",
        },
        {
          text: "分頁新聞版位設定",
          active: true,
        }
      ],
      currentNewsTypePageHashId: '',
      currentNewsTypePageName: '',
      settings: [],
      regions: [],

      fromInfo: [],
      tabIndex: 0,
      showSort: false,
      selected: 'news',
      showSelectOptions: [
        {
          text: '顯示',
          value: true
        },
        {
          text: '不顯示',
          value: false
        }
      ],
      regionTypeOptions: [
        {
          id: 1,
          name: '投票專區'
        },
        {
          id: 2,
          name: '新聞內頁'
        }
      ],
      regionTypes: [41, 42, 43],
      saving: false
    };
  },
  computed: {
    // 整理顯示頁面配置 select 的選項
    settingConfig () {
      return this.settings.map(_setting => {
        const selectedRegion = _setting.region_type
          ? this.regionTypeOptions.find(_option => _option.id === _setting.region_type)
          : null

        const selectedNewsType =
          _setting.region_type === 1
            ? { id: null, name: '-' }
            : !_setting.newstype_id
              ? null
              : this.$root.newsTypeList.find(_type => _type.id === _setting.newstype_id)

        return {
          selectedRegion,
          selectedNewsType,
          sort: _setting.sort
        }
      })
    },
    selectedNewsTypeOptions () {
      return this.settings.map(_setting => _setting.newstype_id)
    },
    // 濾掉選過的分類
    canSelectedNewsTypeOptions () {
      return this.$root.newsTypeList.filter(_type => !this.selectedNewsTypeOptions.includes(_type.id))
    }
  },
  mounted () {
    this.currentNewsTypePageHashId = this.$route.params.NewsTypePageHashID
    this.getPlacementSetting(this.currentNewsTypePageHashId)
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    NewsItem,
    Sort,
    FastImport
  },
  watch: {
    tabIndex (_val) {
      this.fromInfo = this.regions?.[_val]?.news || []
    }
  },
  methods: {
    // 取得目前設定
    getPlacementSetting (_id) {
      const vm = this
      this.$root.apis.getPlacementSetting(_id,
        function (_response) {
          const result = _response.body.data
          vm.currentNewsTypePageHashId = result.id
          vm.currentNewsTypePageName = result.name
          vm.settings = result.setting.map((_set, _index) => {
            return {
              ..._set,
              sort: null
            }
          })
          vm.regions = result.regions.map(_region => {
            const formatNews = formatRegionNewsList(_region.news)
            _region.news = formatNews
            return _region
          })
          vm.fromInfo = vm.regions?.[0]?.news || []
          if (result.regions.length === 0) {
            vm.addNewTab()
          }
        },
        function (_error) {
          console.error(_error.body.data)
        }
      )
    },
    // 新聞快速帶入
    onFastImportNewsList (_news) {
      this.fromInfo = _news.map(_article => {
        return formatImportNews(_article)
      })
      this.updateRegions()
    },
    // 操作 News-Item
    addNewOne () {
      this.fromInfo.push({ ...RegionSettingSchema })
      this.updateRegions()
    },
    deleteOne (_index) {
      this.fromInfo.splice(_index, 1)
      this.updateRegions()
    },
    setItemData (_setItem, _index) {
      this.fromInfo.splice(_index, 1, _setItem)
      this.updateRegions()
    },
    // 根據現在編輯的 news 更新 regions
    updateRegions () {
      this.regions[this.tabIndex].news = this.fromInfo
    },
    // 排序
    onSortClick () {
      this.showSort = true
    },
    onSort (_sorted) {
      this.fromInfo = _sorted
      this.updateRegions()
      this.showSort = false
    },
    // 新增分頁
    addNewTab () {
      if (this.regions.length < 3) {
        const newOne = {
          id: '',
          more: false,
          news: [],
          template: false,
          title: `分頁${this.regions.length + 1}`
        }
        this.regions.push(newOne)
        this.tabIndex = this.regions.length > 1 ? this.regions.length - 1 : 0
      }
    },
    onSaveSetting () {
      const setData = {
        name: this.currentNewsTypePageName,
        setting: this.settings.filter(_setting => _setting.region_type !== null),
        regions: this.regions.map(_region => ({
          name: _region.title,
          more: _region.more,
          items: _region.news
        }))
      }
      this.saving = true
      this.$root.apis.updatePlacementSetting(this.currentNewsTypePageHashId, setData, this.saveSettingSuccess, this.saveSettingError)
    },
    saveSettingSuccess (_response) {
      this.$root.common.showSingleNotify('儲存成功！')
      this.saving = false
    },
    saveSettingError (_error) {
      console.error(_error)
      this.$root.common.showErrorNotify(_error.body.data)
      this.saving = false
    },
    onReleaseSetting () {
      const vm = this
      this.$root.common.confirmAction("發佈組件", '確定要發佈此組件？', "發佈", "再想一下",
        function (_result) {
          vm.$root.apis.releasePlacementSetting(
            vm.currentNewsTypePageHashId,
            function (_response) {
              vm.$root.common.showSingleNotify('發佈成功！')
            },
            vm.onAPIError)
        }
      )
    },
    onAPIError (_error) {
      console.error(_error)
      this.$root.common.showErrorNotify(_error.body.data)
    },
    // 新增顯示頁面配置
    addNewSetting () {
      const newOne = {
        region_type: null,
        newstype_id: null
      }
      this.settings.push(newOne)
    },
    removeSetting (_index) {
      this.settings.splice(_index, 1)
    },
    // 顯示頁面配置 select 改變時
    currentSettingChange (_key, _payload, _index) {
      if (_key === "region_type") {
        this.updateChange(_key, _payload?.id || null, _index)
        // 顯示頁面沒選 or 選擇投票專區
        if (_payload === null || _payload.id === 1) {
          this.updateChange('newstype_id', null, _index)
        }
      } else if (_key === 'newstype_id') {
        this.updateChange(_key, _payload?.id || null, _index)
      } else if (_key === 'sort') {
        this.updateChange(_key, Number(_payload) || null, _index)
      }
    },
    updateChange (_key, _value, _index) {
      const updatedSetting = {
        ...this.settings[_index],
        [_key]: _value
      }
      this.settings.splice(_index, 1, updatedSetting)
    }
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  Sort(:show-sort="showSort" :data-list="fromInfo" @sort="onSort")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" :to="'/placement'") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 基本設定

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-8
                .form-group.mb-3
                  label 組件編號
                  br
                  input.form-control(
                    v-model="currentNewsTypePageHashId"
                    type="text",
                    placeholder="",
                    disabled
                  )
            .row
              .col-lg-8
                .form-group.mb-3
                  label 組件名稱
                  br
                  input.form-control(
                    v-model="currentNewsTypePageName"
                    type="text",
                    placeholder="請輸入組件名稱,限制20個字"
                  )



      b-card.shadow-none.border-0.mt-2(no-body)
        b-tabs(pills, card, vertical, nav-class="bg-white p-0", active-tab-class="pt-1", nav-wrapper-class="col-2", content-class="pt-0")
          b-tab(title="內容配置", active, title-item-class="mb-2")
            b-card-text
              b-card-header(header-tag="header", role="tab")
                .header-row
                  .header-title 內容配置
                  b-button.ml-1.width-lg(
                    v-if="regions.length < 3"
                    variant="primary" 
                    @click="addNewTab"
                  ) 新增分頁
              b-tabs(v-model="tabIndex")
                b-tab(
                  v-for="(_region, _index) in regions" 
                  :key="_region?.id + _index" 
                  :title="_region?.title || `分頁${_index + 1}`" 
                  :active="_index === 0" )
                  .col-12
                    form.form-horizontal(role="form")
                      FastImport(
                        :show-title="true" 
                        :show-max-count="true"
                        :region-type="regionTypes[_index]"
                        :target-id="currentNewsTypePageHashId"
                        @import="onFastImportNewsList"
                      )
                      .row
                        .col-lg-4
                          .form-group.mb-3
                            label 分頁標題
                            br
                            input.form-control(
                              v-model="_region.title"
                              type="text",
                              placeholder="請輸入標題名稱",
                            )

                        //- .col-lg-4
                          .form-group.mb-3
                            label 看更多按鈕
                              i.ml-1.fa.fa-info-circle.text-muted.float-right.info-btn(v-b-tooltip.hover, title="僅限自動帶入時才可使用此功能，如自行編輯新聞排序或內容則無法選擇看更多。")
                            br
                            .radio-group
                              b-form-radio-group#radio-group-1(
                                :id="`radio-options-${_index}`"
                                v-model="_region.more"
                                :options="showSelectOptions"
                                :name="`radio-options-${_index}`")
                    hr
                    label 編輯區
                    .row
                      .edit-row.mb-2
                        b-button.width-md.mb-2(v-if="fromInfo.length > 0" variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
                      b-card.pl-0.pr-0.pm-0.pt-0.col-11.ml-2.mr-2(
                        v-for="(_item, _index) of fromInfo"
                        :key="`NewsItemList-${_index}${_item.newsId}`"
                      )
                        NewsItem(
                          :canUseAd="false"
                          :item="_item" 
                          :index="_index"
                          :allow-edit="true" 
                          @setItemData="setItemData"
                          @removeItem="deleteOne"
                      )
                      b-button.add-btn.col-11.width-lg.m-2(
                        v-if="fromInfo.length < 7" 
                        variant="outline-primary" 
                        @click="addNewOne"
                      ) ＋新增一則 （最多 7 則） 


          b-tab(title="顯示頁面配置", title-item-class="mb-2")
            b-card-text
              b-card-header(header-tag="header", role="tab")
                .header-row
                  .header-title 顯示頁面配置

              .col-11.row.mr-0.ml-0.mt-3.align-items-center.mb-1(v-for="(_setting, _index) in settingConfig" :key="`${_index}-${_setting.selectedNewsType?.id || 'vote'}`")
                .col-12.col-lg-2.pl-lg-0
                  .d-flex.align-items-center
                    span.mr-2 {{ _index + 1 }}.
                    multiselect(
                      :value="_setting.selectedRegion"
                      @input="(_selected) => currentSettingChange('region_type', _selected, _index)"
                      :options="regionTypeOptions",
                      placeholder="選擇顯示頁面",
                      select-label=""
                      label="name",
                      track-by="id",
                    )
                .col-12.col-lg-2.pl-lg-0
                  multiselect(
                    :value="_setting.selectedNewsType",
                    @input="(_selected) => currentSettingChange('newstype_id', _selected, _index)"
                    :options="_setting.selectedRegion?.id === 1 ? [] : canSelectedNewsTypeOptions",
                    placeholder="選擇主分類",
                    select-label=""
                    label="name",
                    track-by="id",
                    )
                .col-12.col-lg-2.pl-lg-0
                  .d-flex.align-items-center
                    input.form-control(
                      type="number"
                      :value="_setting.sort",
                      @input="(_event) => currentSettingChange('sort', _event.target.value, _index)"
                      placeholder="請輸入排序",
                    )
                    b-button.ml-2.del-btn.btn(size="md", variant="dark" @click="removeSetting(_index)")
                      i.mdi.mdi-close 

              b-button.add-btn.col-11.width-lg.m-2(
                variant="outline-secondary" 
                @click="addNewSetting"
                  ) ＋新增一筆 


      .row 
        .col-12.text-center
          b-button.action-btn.width-lg.mr-lg-1(variant="primary" @click="onSaveSetting" :disabled="saving") {{ saving ? '執行中...' : '儲存與發佈' }}
          //- b-button.width-lg(variant="primary" @click="onReleaseSetting") 發佈
       



</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  display: flex
  align-items: center
  position: relative
  &.btn
    padding: 0rem .2rem
    border-radius: 50%
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%

::v-deep .nav-pills 
  border-right: 1px solid #ccc
  .nav-link
    padding: 1rem
    text-align: center
    border-radius: 0
::v-deep .nav-tabs
  justify-content: space-between
  .nav-item
    flex: 1
    text-align: center

::v-deep .tab-pane
  padding-left: 0
  padding-right: 0

#radio-group-2
  ::v-deep .custom-control-inline
    margin-right: 0
.info-btn
  font-size: 18px
  margin-top: 2px

.edit-row
  display: flex
  width: 100%
  justify-content: space-between
  padding: 0 12px

::v-deep.tabs.row.no-gutters
  flex-wrap: nowrap

@media  (max-width: 992px)
  .header-row
    flex-direction: column
    .header-title
      align-self: flex-start
    .btn
      align-self: flex-end
      width: 100%
      margin: 0.5rem 0
  ::v-deep .card-text
    min-width: 240px
  ::v-deep .tab-content
    overflow: scroll
  ::v-deep .nav-pills 
    .nav-link
      padding: 1rem 0.5rem
  .action-btn
    display: block
    width: 100%
</style>
